import React, { useState } from 'react';

import { Button } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';

import MainTitle from '../ContentTypes/MainTitle';
import Subtitle from '../ContentTypes/Subtitle';
import Destacado from '../ContentTypes/Destacado';
import Enlace from '../ContentTypes/Enlace';
import Image from '../ContentTypes/Image';
import List from '../ContentTypes/List';
import HtmlText from '../ContentTypes/HtmlText';
import EmergentWindow from '../ContentTypes/EmergentWindow';
import SimpleTabs from '../ContentTypes/SimpleTabs';
import SimpleAccordion from '../ContentTypes/SimpleAccordion';
import SimpleCarousel from '../ContentTypes/SimpleCarousel';
import SimpleFlipCard from '../ContentTypes/SimpleFlipCard';
import Exercice from '../ContentTypes/Exercice';
import Cita from '../ContentTypes/Cita';

import {
  Container,
  ReverseBox
} from './ContentType.styles';

const getContentType = ({
  type,
  subType,
  label,
  title,
  text,
  link,
  items,
  destacadoType,
  imageName,
  havePendingImage,
  width,
  entregable,
  evaluable
}) => {
  if (type === 'Título x.x') {
    return (
      <MainTitle
        label={label}
        title={title}
      />
    )
  }
  if (type === 'Título x.x.x') {
    return (
      <Subtitle
        title={title}
      />
    )
  }
  if (type === 'Título x.x.x.x') {
    return (
      <Subtitle
        title={title}
      />
    )
  }
  if (type === 'Destacado') {
    return (
      <Destacado
        text={text}
        destacadoType={destacadoType}
        width={width}
      />
    )
  }
  if (type === 'Enlace') {
    return (
      <Enlace
        text={text}
        link={link}
      />
    )
  }
  if (type === 'Imagen') {
    return (
      <Image
        imageName={imageName}
        havePendingImage={havePendingImage}
        width={width}
      />
    )
  }
  if (type === 'Cita') {
    return (
      <Cita
        imageName={imageName}
        text={text}
        width={width}
      />
    )
  }
  if (type === 'Listado topos') {
    return <List items={items} />
  }
  if (type === 'Texto y glosario' || type === 'Tablas') {
    return <HtmlText
      text={text}
    />
  }
  if (type === 'Ejercicio') {
    return <Exercice
      text={text}
      entregable={entregable}
      evaluable={evaluable}
    />
  }
  if (type === 'Ventana emergente') {
    return (
      <EmergentWindow
        text={text}
        title={title}
      />
    )
  }
  if (subType === 'Pestañas') {
    return <SimpleTabs items={items} />
  }
  if (subType === 'Acordeón' || subType === 'Tabla dinámica') {
    return <SimpleAccordion items={items} />
  }
  if (subType === 'Process') {
    return <SimpleCarousel items={items} />
  }
  if (subType === 'Flip Cards') {
    return <SimpleFlipCard items={items} />
  }
  else {
    return <></>;
  }
}
  
  const ContentType = ({
    content,
    editContent,
    deleteContent,
    index
  }) => {
    const [isOptionsVisible, setOptionsVisibility] = useState(false);

    const showOptions = () => {
      setOptionsVisibility(true);
    }
    const hideOptions = () => {
      setOptionsVisibility(false);
    }
    return (
      <Container
        style={{position: 'relative'}}
        onMouseEnter={showOptions}
        onMouseLeave={hideOptions}
        >
        <p>Esto es un/a: {content.subType || content.type}</p>
        {getContentType(content)}
        {isOptionsVisible && (
          <ReverseBox>
            <Button
              color="primary"
              aria-label="edit"
              onClick={() => editContent(content, index)}
              startIcon={<Edit />}
            >
              Editar
            </Button>
            <Button
              color="secondary"
              aria-label="Delete"
              onClick={() => deleteContent(content, index)}
              startIcon={<Delete />}
            >
              Eliminar
            </Button>
          </ReverseBox>
        )}
      </Container>
  );
}
  
  export default ContentType;