import React, { useState } from 'react';

import {
  TextField,
  Grid,
  Button,
  IconButton,
  Checkbox,
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';


const List = ({
  setResult,
  listType,
  items,
  currentWidth,
  animation
}) => {
  const [listItems, setListItems] = useState(items || [{
    id: 1,
    text: '',
    children: []
  }]);
  const [width, setWidth] = useState(100);
  const [haveAnimation, setHaveAnimation] = useState(animation || false);
  const [, updateComponent] = React.useState();
  const forceUpdateComponent = React.useCallback(() => updateComponent({}), []);

  const reassignIds = (items) => items.map((item, index) => {
    const newItem = item;
    newItem.id = (index + 1);
    return newItem;
  })

  const addItem = () => {
    const newItem = {
      id: listItems.length + 1,
      text: '',
      children: []
    }
    setListItems([...listItems, newItem]);
  }
  const addSubItem = (id) => {
    const selectedItem = listItems.find(item => item.id === id);
    const selectedItemIndex = listItems.findIndex(item => item.id === id);
    const newList = listItems;
    const newItem = {
      id: selectedItem.children.length + 1,
      text: '',
      children: []
    }
    selectedItem.children.push(newItem);
    newList[selectedItemIndex] = selectedItem;
    setListItems(newList);
    forceUpdateComponent();
  }
  const addSubSubItem = (id, subItemId) => {
    const selectedItem = listItems.find(item => item.id === id);
    const selectedItemIndex = listItems.findIndex(item => item.id === id);
    const selectedSubItem = selectedItem.children.find(item => item.id === subItemId);
    const selectedSubItemIndex = selectedItem.children.findIndex(item => item.id === subItemId);
    
    const newList = listItems;
    const newSubItem= {
      id: selectedSubItem.children.length + 1,
      text: '',
    }
    newList[selectedItemIndex].children[selectedSubItemIndex].children.push(newSubItem);
    setListItems(newList);
    forceUpdateComponent();
  }
  const removeItem = (id) => {
    const domInput = document.getElementById(`item-${id}`);
    domInput.value = '';

    const filteredById = listItems.filter(item => item.id !== id);
    const reassignedIds = reassignIds(filteredById)

    setListItems(reassignedIds);
    forceUpdateComponent();
  }
  const removeSubItem = (id, subItemId) => {

    const domInput = document.getElementById(`item-${id}-subItem-${subItemId}`);
    domInput.value = '';

    const selectedItemIndex = listItems.findIndex(item => item.id === id);

    const newList = listItems;
    const filteredChildren = listItems[selectedItemIndex].children.filter(child => child.id !== subItemId);
    const reasignedChildrenIds = reassignIds(filteredChildren)
    newList[selectedItemIndex].children = reasignedChildrenIds;
  
    setListItems(newList);
    forceUpdateComponent();
  }
  const removeSubSubItem = (id, subItemId, subSubItemId) => {

    const domInput = document.getElementById(`item-${id}-subItem-${subItemId}-subSubItem-${subSubItemId}`);
    domInput.value = '';

    const selectedItem = listItems.find(item => item.id === id);
    const selectedItemIndex = listItems.findIndex(item => item.id === id);
    const selectedSubItemIndex = selectedItem.children.findIndex(item => item.id === subItemId);

    const newList = listItems;
    const filteredChildren = listItems[selectedItemIndex].children[selectedSubItemIndex].children.filter(child => child.id !== subSubItemId);
    const reasignedChildrenIds = reassignIds(filteredChildren)

    newList[selectedItemIndex].children[selectedSubItemIndex].children = reasignedChildrenIds;
  
    setListItems(newList);
    forceUpdateComponent();
  }
  const handleItem = (value, id) => {
    const selectedItem = listItems.find(item => item.id === id);
    const selectedItemIndex = listItems.findIndex(item => item.id === id);

    selectedItem.text = value;
    const newList = listItems;
    newList[selectedItemIndex] = selectedItem;
    setListItems(newList);
    forceUpdateComponent();
  }
  const handleSubItem = (value, id, childrenId) => {
    const selectedItem = listItems.find(item => item.id === id);
    const selectedItemIndex = listItems.findIndex(item => item.id === id);
    const selectedSubItem = selectedItem.children.find(item => item.id === childrenId);
    const selectedSubItemIndex = selectedItem.children.findIndex(item => item.id === childrenId);

    selectedSubItem.text = value;
    const newList = listItems;
    newList[selectedItemIndex].children[selectedSubItemIndex] = selectedSubItem;
    setListItems(newList);
    forceUpdateComponent();
  }
  const handleSubSubItem = (value, id, subItemId, subSubItemId) => {
    const selectedItem = listItems.find(item => item.id === id);
    const selectedItemIndex = listItems.findIndex(item => item.id === id);
    const selectedSubItem = selectedItem.children.find(item => item.id === subItemId);
    const selectedSubItemIndex = selectedItem.children.findIndex(item => item.id === subItemId);

    const selectedSubSubItemIndex = selectedSubItem.children.findIndex(item => item.id === subSubItemId);

    const newList = listItems;
    newList[selectedItemIndex].children[selectedSubItemIndex].children[selectedSubSubItemIndex].text = value;
    setListItems(newList);
    forceUpdateComponent();
  }
  const handleWidth = (width) => {
    setWidth(width);
  }
  const toogleAnimation = () => {
    const current = !haveAnimation;
    setHaveAnimation(current);
  }
  setResult({
    type: listType,
    width: parseInt(width, 10),
    haveAnimation,
    items: listItems
  });
  return (
    <form
      style={{width: '100%', marginTop: '10px', display: 'flex'}}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            type="number"
            fullWidth
            defaultValue={currentWidth || 100}
            name="width"
            onChange={(event) => handleWidth(event.target.value)}
            helperText="Ancho"
          />
        </Grid>
        <Grid item xs={4}>
          <Checkbox checked={haveAnimation} onChange={toogleAnimation}/> Animación
        </Grid>
        {listItems.map(({id, text, children}) => (
          <>
          <Grid item xs={8}>
            <TextField
              key={id}
              fullWidth
              id={`item-${id}`}
              onChange={(event) => handleItem(event.target.value, id)}
              defaultValue={text}
              name="itemList"
              helperText={`Texto Item ${id}`}
            />
            {children.map(childItem => (
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <TextField
                    key={childItem.id}
                    fullWidth
                    style={{ marginLeft: '30px' }}
                    id={`item-${id}-subItem-${childItem.id}`}
                    defaultValue={childItem.text}
                    onChange={(event) => handleSubItem(event.target.value, id, childItem.id)}
                    name="subItem"
                    helperText={`Texto Sub-item ${childItem.id}`}
                  />
                  {childItem.children?.map(subChilden => (
                    <Grid container spacing={2}>
                      <Grid item xs={10}>
                        <TextField
                          key={subChilden.id}
                          fullWidth
                          style={{ marginLeft: '60px' }}
                          id={`item-${id}-subItem-${childItem.id}-subSubItem-${subChilden.id}`}
                          defaultValue={subChilden.text}
                          onChange={(event) => handleSubSubItem(event.target.value, id, childItem.id, subChilden.id)}
                          name="subItem"
                          helperText={`Texto Sub-sub-item ${subChilden.id}`}
                        />
                      </Grid>
                      <Grid item={false}>
                        <IconButton
                          color="secondary"
                          aria-label="remove subitem"
                          component="span"
                          onClick={() => removeSubSubItem(id, childItem.id, subChilden.id)}
                        >
                          <Delete />
                        </IconButton>
                        </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={3}>
                  <IconButton
                    color="primary"
                    aria-label="add subitem"
                    component="span"
                    onClick={() => addSubSubItem(id, childItem.id)}
                  >
                    <Add />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    aria-label="remove subitem"
                    component="span"
                    onClick={() => removeSubItem(id, childItem.id)}
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={2}>
            <IconButton
              color="primary"
              aria-label="add subitem"
              component="span"
              onClick={() => addSubItem(id)}
            >
              <Add />
            </IconButton>
            {listItems.length > 1 && (
              <IconButton
                color="secondary"
                aria-label="remove subitem"
                component="span"
                onClick={() => removeItem(id)}
              >
              <Delete />
            </IconButton>
            )}
          </Grid>
        </>
        ))}
      </Grid>
      <Button
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={addItem}
        style={{position: 'fixed', right: '25px', top: '85px' }}
      >
        Añadir Item
      </Button>
    </form>
  )
}
    
export default List;