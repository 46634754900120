import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
`;
export const ReverseBox = styled.div`
  display: flex;
  justify-content: center;
  place-content: space-evenly;
  height: 40px;
  width: 200px;
  position: absolute;
  top: -3px;
  background: #c5c5c538;
`;

