import { Container } from './Cita.styles';
    
const Cita = ({
  text,
  imageName,
}) => (
  <Container
    imageName={imageName}
  >
    <div dangerouslySetInnerHTML={{__html: text}} />
  </Container>
)

export default Cita;  