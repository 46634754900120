    
import styles from './styles.module.css';

const EmergentWindow = ({ text, title }) => (
  <div>
    <h5>{title}</h5>
    <div className={styles.htmlTextContainer}>
      <div dangerouslySetInnerHTML={{__html: text}} />
    </div>
  </div>
)
    
export default EmergentWindow;