import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {
  Grid,
  Paper,
  Divider,
  Typography,
  Box,
  Button,
  Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import {
  DragDropContext,
  Droppable,
  Draggable
} from "react-beautiful-dnd";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";

import { signOut } from '../calls/login';

import AppHeader from '../components/AppHeader'
import ContentType from '../components/ContentType';
import DeleteModal from '../components/DeleteModal';
import EditModal from '../components/EditModal';
import FieldModal from '../components/FieldModal';
import Page from '../components/Page';
import PortadaForm from '../components/PortadaForm';

import data from '../data/pages.json';
import contentData from '../data/contentTypes.json';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    overflow: 'hidden',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  appBar: {
    marginBottom: '20px'
  },
  title: {
    flexGrow: 1,
  },
  page: {
    cursor: 'pointer',
    flexGrow: 1,
    padding: '10px',
  },
  selectedPage: {
    flexGrow: 1,
    padding: '10px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  modal: {
    position: 'absolute',
    width: 900,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: '800px',
    overflowY: 'auto',
    overflowX: 'hidden',
  };
}


function Editor() {
  const classes = useStyles();
  const [pageList, setPageList] = useState([]);
  const [sucess, setSuccess] = useState({});
  const [selectedPage, setSelectedPage] = useState({});
  const [editableContent, setEditableContent] = useState({});
  const [editedContentIndex, setCurrentEdittingIndex] = useState(0);
  const [modalStyle] = useState(getModalStyle);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [, updateComponent] = React.useState();
  const forceUpdateComponent = React.useCallback(() => updateComponent({}), []);
  const history = useHistory();

  useEffect(() => {
    const [page] = pageList.filter(page => page.selected === true);
    setSelectedPage(page);
  }, [pageList]);

  useEffect(() => {
    const localData =  localStorage.getItem('scormContentData');
    if (localData) {
      setPageList(JSON.parse(localData))
    } else {
      setPageList(data.pages)
    }
  }, []);

  const addPage = () => {
    const newPageList = [...pageList, {
      id: pageList.length,
      selected: false,
      content: []
    }]
    setPageList(newPageList);
  }

  const selectPage = (id) => {
    const newPageList = pageList.map(page => {
      const newPage = page;
      if (newPage.id === id) {
        newPage.selected = true;
        return newPage
      }
      newPage.selected = false;
      return newPage;
    });
    const [page] = newPageList.filter(page => page.selected === true);
    setPageList(newPageList);
    setSelectedPage(page)
  }

  const removePage = (id) => {
    const removePageWIthId = pageList.filter(page => page.id !== id);
    const reAssignIndex = removePageWIthId.map((page, index) => {
      const newPage = page;
      newPage.id = index;
      return newPage;
    })
    setPageList(reAssignIndex);
  }

  const openModal = () => {
    setModalOpen(true);
  }
  const closeModal = () => {
    setModalOpen(false);
    setEditModalOpen(false);
    setDeleteModalOpen(false);
  }

  const saveData = () => {
    localStorage.setItem('scormContentData', JSON.stringify(pageList));
    openSuccess('El contenido se ha guardado correctamente');
  }
  const deleteData = () => {
    setDeleteModalOpen(false);
    localStorage.removeItem('scormContentData');
    setPageList(data.pages);
    openSuccess('El contenido se ha eliminado correctamente');
  }

  const openSuccess = (text) => {
    setSuccess({
      open: true,
      text,
    })
  }

  const downloadJson = () => {
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(pageList));
    const link = document.createElement('A');
    link.href = dataStr;
    link.download = 'scormContent.json';
    document.body.appendChild(link);
    link.click();
    link.remove();
    openSuccess('El contenido se ha generado y descargado correctamente');
  }
  const uploadJson = () => {
    const input = document.createElement('INPUT');
    input.type = 'file';
    input.click();
    input.addEventListener('change', (event) => {
      const [ file ] = input.files;
      
      const reader = new FileReader();
      reader.onload = function() {
        const fileContent = JSON.parse(reader.result);
        setPageList(fileContent);
        openSuccess('El fichero se ha cargado correctamente');
      };
      reader.readAsText(file); 
    })
    input.remove();
  }

  const closeSuccess = () => {
    setSuccess({ open: false })
  }

  const saveContentType = (result) => {
    closeModal();
    const newSelectedPage = selectedPage;
    newSelectedPage.content.push(result);
    setSelectedPage(newSelectedPage)
  }
  const editContentType = (result) => {
    closeModal();
    const newSelectedPage = selectedPage;
    newSelectedPage.content[editedContentIndex] = result;
    setSelectedPage(newSelectedPage)
  }
  const editContent = (content, index) => {
    setEditModalOpen(true);
    setCurrentEdittingIndex(index)
    setEditableContent(content);
  }
  const deleteContent = (content, index) => {
    const newSelectedPage = selectedPage;
    newSelectedPage.content.splice(index, 1);
    setSelectedPage(newSelectedPage)
    forceUpdateComponent();
  }
  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  }
  const savePortada = () => {
    const container = document.getElementById('portadaForm');
    const inputs = container.querySelectorAll('input')
    const inputsArray = Array.from(inputs);
    const values = inputsArray.map(({name, value}) => {
      return {
        [name]: value
      }
    });
    const result = {};
    for (var i = 0; i < values.length; i++) {
      result[[Object.keys(values[i])]] = values[i][Object.keys(values[i])];
    }
    const newPageList = pageList;
    newPageList[0].content = result;
    setPageList(newPageList);
    forceUpdateComponent();
  }
  const updatePageTitle = (title, id) => {
    const currentPageIndex = pageList.findIndex(page => page.id === id);
    const newPageList = pageList;
    newPageList[currentPageIndex].title = title;
    setPageList(newPageList);
    openSuccess(`Página ${title} renombrada correctamente`);
    forceUpdateComponent();
  }
  const clonePage = (id) => {
    const currentPage = pageList.find(page => page.id === id);
    const newPage = cloneDeep(currentPage);
    const newPageList = pageList;
    newPage.selected = false;
    newPage.id = newPageList.length;
    newPage.title = `${currentPage.title || `Página ${id}`} copia`;
    newPageList.push(newPage);

    setPageList(newPageList);
    openSuccess(`Página ${newPage.title} clonada correctamente`);
    forceUpdateComponent();
  }

  const getContentWidth = (width) => {
    if (parseInt(width, 10) <= 25) {
      return '23%';
    }
    if (parseInt(width, 10) <= 33) {
      return '31%';
    }
    if (parseInt(width, 10) <= 50) {
      return '48%';
    }
    if (parseInt(width, 10) <= 75) {
      return '74%';
    }
    return '98%';
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: '5px',

    // change background colour if dragging
    background: isDragging ? "#f5855154" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
  });
  const getContentItemStyle = (isDragging, draggableStyle, width) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    margin: '5px',
    flexGrow: 0,
    maxWidth: getContentWidth(width),
    flexBasis: getContentWidth(width),

    // change background colour if dragging
    background: isDragging ? "#f5855154" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#bbbbbb" : "white",
  });
  const getContentListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#bbbbbb" : "white",
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'auto',
  });

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      pageList,
      result.source.index,
      result.destination.index
    );
    setPageList(items);
    openSuccess('Página reordenada correctamente');
  }
  const onDragEndContent = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      selectedPage.content,
      result.source.index,
      result.destination.index
    );
    const newSelectedPage = selectedPage;
    newSelectedPage.content = items;
    setSelectedPage(newSelectedPage);
    openSuccess('Contenido reordenado correctamente');
  }
  const logOut = async() => {
    await signOut()
    history.push('/login');
  }
  return (
    <div>
      <AppHeader
        appBarStyle={classes.appBar}
        titleStyle={classes.title}
        saveData={saveData}
        downloadJson={downloadJson}
        uploadJson={uploadJson}
        openDeleteModal={openDeleteModal}
        logOut={logOut}
      />
      <Grid container spacing={2}>
        <Grid item xs={2}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Paper className={classes.paper} style={{ overflowY: 'auto', maxHeight: '90vh' }}>
            <Typography variant="h5" component="h2">Listado de Páginas</Typography>
            <Divider />
            <Box m={2} />
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
              <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {pageList.map((item, index) => (
                    <Draggable key={item.id} draggableId={`item-${item.id}-${index}`} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <Page
                            key={item.id}
                            id={item.id}
                            clonePage={clonePage}
                            modalClass={classes.modal}
                            modalStyle={modalStyle}
                            pageStyle={classes.page}
                            removePage={removePage}
                            selected={item.selected}
                            selectedPageStyle={classes.selectedPage}
                            selectPage={selectPage}
                            title={item.title}
                            updatePageTitle={updatePageTitle}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
              </Droppable>
              <Box m={2}>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={addPage}
                >
                  Añadir
                </Button>
              </Box>
            </Paper>
          </DragDropContext>
        </Grid>
        <Grid item xs={10}>
            {selectedPage?.id === 0 && (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <PortadaForm
                  title={selectedPage.content?.title}
                  unit={selectedPage.content?.unit}
                  image={selectedPage.content?.image}
                  icon={selectedPage.content?.icon}
                />
                <Button
                  variant="contained"
                  size="small"
                  color='primary'
                  onClick={savePortada}
                >
                  Guardar
                </Button>
              </div>
            )}
            {selectedPage?.id > 0 && (
            <Paper className={classes.paper}>
              {!selectedPage.content?.length && (
                <h3>Todavía no se ha añadido ningún contenido</h3>
              )}
              {selectedPage.content?.length > 0 && (
                <DragDropContext onDragEnd={onDragEndContent}>
                  <Box m={2}>
                    <Paper elevation={1} className={classes.paper}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getContentListStyle(snapshot.isDraggingOver)}
                          >
                            {selectedPage?.content?.map((item, index) => (
                              <Draggable key={index} draggableId={`content-${index}`} index={index}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getContentItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style,
                                      item.width
                                    )}
                                  >
                                    <ContentType
                                      key={index}
                                      index={index}
                                      editContent={editContent}
                                      deleteContent={deleteContent}
                                      content={item}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </Paper>
                  </Box>
                </DragDropContext>
              )}
              <Box m={2}>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={openModal}
                >
                  Añadir Campo 
                </Button>
              </Box>
            </Paper>
          )}
        </Grid>
      </Grid>
      <FieldModal
        modalStyle={modalStyle}
        closeModal={closeModal}
        isModalOpen={isModalOpen}
        modalClass={classes.modal}
        contentTypes={contentData.contentTypes}
        saveContentType={saveContentType}
      />
      <EditModal
        modalStyle={modalStyle}
        closeModal={closeModal}
        isModalOpen={isEditModalOpen}
        modalClass={classes.modal}
        content={editableContent}
        editContentType={editContentType}
      />
      <DeleteModal
        modalStyle={modalStyle}
        closeModal={closeModal}
        isModalOpen={isDeleteModalOpen}
        modalClass={classes.modal}
        deleteData={deleteData}
      />
      <Snackbar open={sucess.open} autoHideDuration={3000} onClose={closeSuccess}>
        <MuiAlert onClose={closeSuccess} severity="success">
          {sucess.text}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default withRouter(Editor);
