import styled from 'styled-components';

export const Title = styled.h2`
  padding: 0;
  margin-bottom: 1.5rem;
  color: #f58551;
  font-weight: 600;
  font-size: 1.3rem;
`;

