import {
    Container
  } from './Enlace.styles';
    
    const Enlace = ({
      text,
      link
    }) => (
      <Container>
        <a href={link}>{text}</a>
      </Container>
    )
    
    export default Enlace;