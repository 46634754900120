import React, { useState } from 'react';
import {
  Paper,
  Modal,
  TextField,
  Button,
  Box,
} from '@material-ui/core';

const DeleteModal = ({
  modalClass,
  isModalOpen,
  closeModal,
  modalStyle,
  deleteData,
}) => {
  const [securityPhrase, setSecurityPhrase] = useState({});
  const handleChange = (value) => {
    setSecurityPhrase(value)
  }
  return (
    <Modal
      id='fieldModal'
      open={isModalOpen}
      onClose={closeModal}
      scroll='paper'
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper style={modalStyle} className={modalClass}>
        <p>Seguro que quieres borrar este contenido? Teclea <i>Quiero eliminar este contenido</i> para poder eliminar.</p>
        <TextField
          id="delete"
          label="Pon la frase de seguridad"
          name='delete'
          onChange={(event) => handleChange(event.target.value)}
          fullWidth
        />
        <Box m={2} />
        <Button
          variant="contained"
          size="small"
          color="secondary"
          disabled={securityPhrase !== 'Quiero eliminar este contenido'}
          fullWidth
          onClick={deleteData}
        >
          Borrar
      </Button>
      </Paper>
    </Modal>
  );
}

export default DeleteModal;