import {
    Container
  } from './Destacado.styles';
    
    const Destacado = ({
      text,
      destacadoType,
    }) => (
      <Container
        destacadoType={destacadoType}
      >
        <div dangerouslySetInnerHTML={{__html: text}} />
      </Container>
    )
    
    export default Destacado;