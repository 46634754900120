import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
} from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';

const TableEditor = ({
  setResult,
  text,
  currentWidth
}) => {
  const [width, setWidth] = useState(currentWidth || 100);
  const [content, setContent] = useState(text || '');

  const handleEditorChange = (content) => {
    setContent(content);
  }
  const handleWidth = (value) => setWidth(value);
  useEffect(() => {
    setResult({
      type: 'Tablas',
      text: content,
      width: parseInt(width, 10),
    });
  }, [text, width, content, setResult]);
  return (
    <Box m={2}>
      <TextField
        type="number"
        defaultValue={width}
        name="width"
        onChange={(event) => handleWidth(event.target.value)}
        helperText="Ancho"
      />
      <Box m={2} />
      <Editor
        apiKey="wpgdkh9gdhce4boqq7eqgahj601n8ca77pcfdxcx6vddgvc3"
        initialValue={content}
        init={{
          height: 300,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            `undo redo | formatselect | bold italic backcolor |
            table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol
            alignleft aligncenter alignright alignjustify |
            bullist numlist outdent indent | removeformat | help`
        }}
        onEditorChange={handleEditorChange}
      />
    </Box>
  )
}

  export default TableEditor
