import styled from 'styled-components';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

export const StyledTab = styled(Tab)`
  background-color: white;
  color: #f58551;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  &:hover {
    background-color: white;
  }
`;
export const StyledTabs = styled(Tabs)`
  background-color: white;
  color: #f58551;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  &:hover {
    background-color: white;
  }
`;

