import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Grid,
} from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';

const DestacadoForm = ({
  setResult,
  text,
  currentWidth,
  currentType,
}) => {
  const [width, setWidth] = useState(currentWidth || 100);
  const [content, setContent] = useState(text || '');
  const [type, setType] = useState(currentType || '');
  const handleEditorChange = (content) => {
    setContent(content);
  }
  const handleWidth = (value) => setWidth(value);
  const handleType = (value) => setType(value);
  useEffect(() => {
    setResult({
      destacadoType: type,
      type: 'Destacado',
      text: content,
      width: parseInt(width, 10),
    });
  }, [text, width, content, type, setResult]);
  return (
    <Box>
      <form
        style={{width: '100%', marginTop: '10px', display: 'flex'}}
        noValidate
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <TextField
              id={`tipo-${type}`}
              defaultValue={type}
              type="number"
              name="destacadoType"
              helperText="Tipo"
              onChange={(event) => handleType(event.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id={`width-${type}`}
              defaultValue={width || 100}
              type="number"
              helperText="Ancho"
              name='width'
              onChange={(event) => handleWidth(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
          <Editor
            apiKey="wpgdkh9gdhce4boqq7eqgahj601n8ca77pcfdxcx6vddgvc3"
            initialValue={content || ""}
            init={{
              height: 300,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                `undo redo | formatselect | bold italic backcolor |
                alignleft aligncenter alignright alignjustify |
                bullist numlist outdent indent | removeformat | help`
            }}
            onEditorChange={handleEditorChange}
          />
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

  export default DestacadoForm
