
import React, { useState } from 'react';
import {
  Paper,
  Typography,
  Box,
  IconButton,
  Menu ,
  MenuItem,
  Modal,
  TextField,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MenuOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
  },
}));

const Page = ({
  id,
  selected,
  selectPage,
  removePage,
  selectedPageStyle,
  pageStyle,
  title,
  modalStyle,
  modalClass,
  updatePageTitle,
  clonePage,
}) => {
    const [anchorEl, setAnchorEl] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [editedTitle, setEditedTitle] = useState(title || '');

    const classes = useStyles();

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleTitle = (value) => setEditedTitle(value);
    const handleClose = () => setAnchorEl(null);
    const openModal = () => {
      handleClose(null);
      setModalOpen(true);
    };
    const closeModal = () => setModalOpen(false)
    const updatePageTitleModal = (editedTitle, id) => {
      closeModal();
      updatePageTitle(editedTitle, id);
    }
    const clonePageAndCloseModal = (id) => {
      handleClose(null);
      clonePage(id);
    }

    return (
    <Box
      key={id}
      m={1}
      className={classes.box}
    >
      <Paper
        className={selected ? selectedPageStyle : pageStyle}
        onClick={() => selectPage(id)}
        >
        <Typography
          variant="body1"
          component="p"
        >
          {title || `Página ${id}`} 
        </Typography>
      </Paper>
      {id > 0 && (
        <>
          <Box m={1} />
          <IconButton color='primary' size='small' onClick={handleClick}>
            <MenuOutlined fontSize="small" />
          </IconButton>
        </>
      )}
      <Menu
        id={`simple-menu-${id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => clonePageAndCloseModal(id)}>Clonar</MenuItem>
        <MenuItem onClick={openModal}>Editar nombre</MenuItem>
        <MenuItem onClick={() => removePage(id)}>Eliminar</MenuItem>
      </Menu>
      <Modal
        id='fieldModal'
        open={isModalOpen}
        onClose={closeModal}
        scroll='paper'
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper style={modalStyle} className={modalClass}>
          <Box m={2}>
            <TextField
              fullWidth
              id="title"
              label="Title"
              name='title'
              onChange={(event) => handleTitle(event.target.value)}
              defaultValue={editedTitle || `Página ${id}`}
            />
          </Box>
          <Box m={2}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              aria-label="edit"
              onClick={() => updatePageTitleModal(editedTitle, id)}
            >
              Guardar
            </Button>
          </Box>
        </Paper>
      </Modal>
    </Box>
  );
}

export default Page;