import Flippy, { FrontSide, BackSide } from 'react-flippy';
import { Container } from './SimpleFlipCard.styles';

const SimpleFlipCard = ({ items }) => (
  <Container>
    {items.map(({
      id,
      text,
      title,
      image,
    }) => (
      <Flippy
        key={id}
        flipOnHover={false} // default false
        flipOnClick={true} // default false
        flipDirection="horizontal" // horizontal or vertical
        // if you pass isFlipped prop component will be controlled component.
        // and other props, which will go to div
        style={{ width: '400px', height: '400px', margin: '10px' }} /// these are optional style, it is not necessary
      >
        <FrontSide
          style={{
            backgroundColor: '#41669d',
          }}
        >
          <h1>Title: {title}</h1>
          <p>El background será con la imagen: {image}</p>
        </FrontSide>
        <BackSide
          style={{ backgroundColor: '#175852'}}>
          <div style={{ color: 'white' }} dangerouslySetInnerHTML={{ __html: text }} />
        </BackSide>
      </Flippy>
    ))}
  </Container>
)
    
export default SimpleFlipCard;