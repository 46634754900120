import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Grid,
  Accordion ,
  AccordionSummary ,
  AccordionDetails,
  Typography,
  Checkbox,
  Button,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import { ExpandMore } from '@material-ui/icons';

const Desplegable = ({
  setResult,
  currentWidth,
  items,
  subType
 }) => {

  const defaultAccordionList = [
    {
      id: 1,
      haveImage: false,
      text: '',
      image: '',
    }
  ]

  const desplegableTypes = [
    'Acordeón',
    'Tabla dinámica',
    'Pestañas',
    'Process',
    'Flip Cards',
  ]

  const availableWidth = [25, 33, 50, 75, 100];
  const [accordionList, setAccordionList] = useState(items || defaultAccordionList);
  const [expanded, setExpanded] = useState('panel1');
  const [selectedType, setSelectedType] = useState(subType || null);
  const [selectedWidth, setSelectedWidth] = useState(currentWidth || 100);
  const [, updateComponent] = React.useState();
  const forceUpdateComponent = React.useCallback(() => updateComponent({}), []);

  const handleEditorChange = (id, content) => {
    const newAccordionList = accordionList;
    const accordionIndex = accordionList.findIndex(accordion => accordion.id === id);
    newAccordionList[accordionIndex].text = content
    setAccordionList(newAccordionList);
    forceUpdateComponent();
  }
  const handleSelectedTypeChange = (type) => {
    setSelectedType(type);
  };
  const handleSelectedWidthChange = (width) => {
    setSelectedWidth(width);
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleTitle = (id, title) => {
    const newAccordionList = accordionList;
    const accordionIndex = accordionList.findIndex(accordion => accordion.id === id);
    newAccordionList[accordionIndex].title = title
    setAccordionList(newAccordionList);
    forceUpdateComponent();
  };
  const handleImage = (id, image) => {
    const newAccordionList = accordionList;
    const accordionIndex = accordionList.findIndex(accordion => accordion.id === id);
    newAccordionList[accordionIndex].image = image
    setAccordionList(newAccordionList);
    forceUpdateComponent();
  };

  const toogleHaveImage = (id) => {
    const newAccordionList = accordionList;
    const accordionIndex = accordionList.findIndex(accordion => accordion.id === id);
    newAccordionList[accordionIndex].haveImage = !newAccordionList[accordionIndex].haveImage;
    setAccordionList(newAccordionList);
    forceUpdateComponent();
  }

  const addItem = () => {
    const newItem = {
      id: accordionList.length + 1,
      haveImage: false,
      text: '',
      image: '',
    }
    setAccordionList([...accordionList, newItem]);
  }
  const deleteItem = (id) => {
    const newAccordionList = accordionList.filter(item => item.id !== id);
    setAccordionList(newAccordionList);
  }

  const tooglePendingImage = (id) => {
    const newAccordionList = accordionList;
    const accordionIndex = accordionList.findIndex(accordion => accordion.id === id);
    newAccordionList[accordionIndex].havePendingImage = !newAccordionList[accordionIndex].havePendingImage;
    setAccordionList(newAccordionList);
    forceUpdateComponent();
  }

  useEffect(() => {
    if (accordionList) {
      setAccordionList(accordionList);
    }
    setResult({
      type: 'Desplegable',
      subType: selectedType,
      width: selectedWidth,
      items: accordionList,
    });
  }, [currentWidth, subType, setResult, selectedType, selectedWidth, accordionList]);
  return (
    <Box m={2}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Select
            labelId="selectModalFieldLabel"
            id="selectModalField"
            fullWidth
            onChange={(event) => handleSelectedTypeChange(event.target.value)}
            value={selectedType}
            >
            {desplegableTypes.map((item, index) => (
              <MenuItem
                key={index}
                value={item}
              >
                {item}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="caption">Selecciona tipo de desplegable</Typography>
        </Grid>
        <Grid item xs={4}>
          <Select
            labelId="selectWidthLabel"
            id="selectWidth"
            fullWidth
            onChange={(event) => handleSelectedWidthChange(event.target.value)}
            defaultValue={selectedWidth}
            >
            {availableWidth.map((item, index) => (
              <MenuItem
                key={index}
                value={item}
              >
                {item}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="caption">Selecciona el ancho</Typography>
        </Grid>
        <Grid item xs={4}>
          <Button
            color="primary"
            aria-label="upload picture"
            fullWidth
            component="span"
            onClick={addItem}
          >
            Añadir Item
          </Button>
        </Grid>
      </Grid>
      {accordionList.map(({
        id,
        haveImage,
        text,
        image,
        havePendingImage,
        title
      }) => (
        <Box key={id} m={2}>
          <Accordion expanded={expanded === `panel${id}`} onChange={handleChange(`panel${id}`)}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Desplegable {id}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Box m={2} />
                <Grid item xs={6}>
                  <TextField
                    type="text"
                    fullWidth
                    defaultValue={title || ''}
                    label='Pon aquí un título'
                    helperText='Título'
                    name="title"
                    onChange={(event) => handleTitle(id, event.target.value)}
                  />
                </Grid>
                {selectedType !== 'Flip Cards' && selectedType !== 'Process' && (
                  <Grid item xs={2}>
                    <Checkbox checked={haveImage} onChange={() => toogleHaveImage(id)}/> Imagen
                  </Grid>
                )}
                <Grid item xs={3}>
                  <Button
                    color="secondary"
                    aria-label="delete idem"
                    fullWidth
                    component="span"
                    onClick={() => deleteItem(id)}
                  >
                    Eliminar
                  </Button>
                </Grid>
                <Box m={2} />
                <Grid container spacing={2}>
                  <Grid item xs={haveImage ? 6 : 12}>
                    <Editor
                      apiKey="wpgdkh9gdhce4boqq7eqgahj601n8ca77pcfdxcx6vddgvc3"
                      initialValue={text || ""}
                      init={{
                        height: 150,
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                          `undo redo | formatselect | bold italic backcolor |
                          alignleft aligncenter alignright alignjustify |
                          bullist numlist outdent indent | removeformat | help`
                      }}
                      onEditorChange={(content) => handleEditorChange(id, content)}
                    />
                  </Grid>
                  {(haveImage || (selectedType === 'Flip Cards' || selectedType === 'Process')) && (
                    <>
                      <Grid item xs={4}>
                        <TextField
                          type="text"
                          fullWidth
                          defaultValue={image || ''}
                          label='Pon aquí una imagen'
                          helperText='Imagen'
                          name="image"
                          onChange={(event) => handleImage(id, event.target.value)}
                        />
                      </Grid>
                        <Grid item xs={2}>
                        <Checkbox checked={havePendingImage} onChange={() => tooglePendingImage(id)}/> Pendiente
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
    </Box>
  )
}

  export default Desplegable
