import React, { useState } from 'react';
import { Box, TextField } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';

const EmergentWindow = ({ setResult, text, currentTitle }) => {
  const [title, setTitle] = useState('');
  const handleEditorChange = (content) => {
    setResult({
      type: 'Ventana emergente',
      text: content,
      title,
    });
  }
  const handleTitle = (value) => setTitle(value);
  return (
    <Box m={2}>
      <TextField
        type="text"
        fullWidth
        defaultValue={title || 'Pon aquí un título'}
        name="title"
        onChange={(event) => handleTitle(event.target.value)}
        helperText="Titulo"
      />
      <Box m={2} />
      <Editor
        apiKey="wpgdkh9gdhce4boqq7eqgahj601n8ca77pcfdxcx6vddgvc3"
        initialValue={text || "<p>Pon aquí el contenido del texto de la ventana emergente</p>"}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            `undo redo | formatselect | bold italic backcolor |
            alignleft aligncenter alignright alignjustify |
            bullist numlist outdent indent | removeformat | help`
        }}
        onEditorChange={handleEditorChange}
      />
    </Box>
  )
}

  export default EmergentWindow
