import {
    TextField,
    Box,
    Grid,
    Button,
  } from '@material-ui/core';
  
import List from './FormFields/List';
import TextEditor from './FormFields/TextEditor';
import EmergentWindow from './FormFields/EmergentWindow';
import Desplegable from './FormFields/Desplegable';
import TableEditor from './FormFields/TableEditor/TableEditor';
import Exercice from './FormFields/Exercice';
import Image from './FormFields/Image';
import Cita from './FormFields/Cita';
import DestacadoForm from './FormFields/Destacado';

  const getContentType = (type, {
    text,
    label,
    title,
    width,
    destacadoType,
    imageName,
    link,
    subType,
    items,
    haveAnimation,
    havePendingImage,
    evaluable,
    entregable 
  }, setResult) => {
    if (type === 'Título x.x') {
      return (
        <Box>
          <form style={{width: '100%', marginTop: '10px', display: 'flex'}} noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <TextField id="label" label="Label" name='label' defaultValue={label || ''} />
              </Grid>
              <Grid item xs={10}>
                <TextField fullWidth id="title" label="Title" name='title' defaultValue={title || ''} />
              </Grid>
            </Grid>
          </form>
        </Box>
      )
    }
    if (type === 'Título x.x.x' || type === 'Título x.x.x.x') {
      return (
        <Box>
          <form style={{width: '100%', marginTop: '10px', display: 'flex'}} noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField fullWidth id="title" label="Title" name='title' defaultValue={title || ''} />
              </Grid>
            </Grid>
          </form>
        </Box>
      )
    }
    if (type === 'Imagen') {
      return (
        <Image
          setResult={setResult}
          type={type}
          currentImageName={imageName}
          currentWidth={width}
          pendingImage={havePendingImage}
        />
      )
    }
    if (type === 'Destacado') {
      return (
        <DestacadoForm
          currentType={destacadoType}
          currentWidth={width}
          setResult={setResult}
          text={text}
        />
      )
    }
    if (type === 'Enlace') {
      return (
        <Box>
          <form
            style={{width: '100%', marginTop: '10px', display: 'flex'}}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField fullWidth id="enlaceText" label="Texto" name="text" defaultValue={text || ''}/>
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth id="enlaceLink" label="Enlace" name="link" defaultValue={link || ''}/>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id={`width-${type}`}
                  defaultValue={width || 100}
                  type="number"
                  helperText="Ancho"
                  name='width'
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      )
    }
    if (type === 'Listado topos') {
      return (
        <List
          setResult={setResult}
          listType={type}
          items={items}
          currentWidth={width}
          animation={haveAnimation}
        />
      )
    }
    if (type === 'Texto y glosario') {
      return (
        <TextEditor
          text={text}
          currentWidth={width}
          setResult={setResult}
          animation={haveAnimation}
        />
      )
    }
    if (type === 'Ejercicio') {
      return (
        <Exercice
          text={text}
          currentWidth={width}
          setResult={setResult}
          currentEvaluable={evaluable}
          currentEntregable={entregable}
        />
      )
    }
    if (type === 'Tablas') {
      return (
        <TableEditor
          text={text}
          currentWidth={width}
          setResult={setResult}
        />
      )
    }
    if (type === 'Ventana emergente') {
      return (
        <EmergentWindow
          currentTitle={title}
          text={text}
          setResult={setResult}
        />
      )
    }
    if (type === 'Desplegable') {
      return (
        <Desplegable
          items={items}
          subType={subType}
          currentWidth={width}
          setResult={setResult}
          animation={haveAnimation}
        />
      )
    }
    if (type === 'Cita') {
      return (
        <Cita
          text={text}
          currentImageName={imageName}
          currentWidth={width}
          setResult={setResult}
          animation={haveAnimation}
        />
      )
    }
    else {
      return <></>;
    }
  }

  const saveDefaultContent = () => {
    const modal = document.getElementById('fieldModal');
    const modalFields = modal.querySelectorAll('input');
    const modalFieldsArray = Array.from(modalFields);
    
    const formObj = modalFieldsArray.map(({name, value}) => {
      if(name === '') {
        name = 'type'
      }
      return {
        [name]: value
      }
    })
    const result = {};
    for (var i = 0; i < formObj.length; i++) {
      result[[Object.keys(formObj[i])]] = formObj[i][Object.keys(formObj[i])];
    }
    return result;
  }

  const saveListContent = (fieldResult) => {
    return fieldResult;
  }
  const saveTextEditor = (fieldResult) => {
    return fieldResult;
  }
  const saveTablas = (fieldResult) => {
    return fieldResult;
  }
  const saveExercice = (fieldResult) => {
    return fieldResult;
  }
  const saveEmergentWindow = (fieldResult) => {
    return fieldResult;
  }
  const saveDesplegable = (fieldResult) => {
    return fieldResult;
  }
  const saveImagen = (fieldResult) => {
    return fieldResult;
  }
  const saveCita = (fieldResult) => {
    return fieldResult;
  }
  const saveDestacado = (fieldResult) => {
    return fieldResult;
  }

  const checkContentAndSave = (type, result) => {
    if (type === 'Listado topos') {
      return saveListContent(result);
    }
    if (type === 'Texto y glosario') {
      return saveTextEditor(result);
    }
    if (type === 'Ejercicio') {
      return saveExercice(result);
    }
    if (type === 'Ventana emergente') {
      return saveEmergentWindow(result);
    }
    if (type === 'Tablas') {
      return saveTablas(result);
    }
    if (type === 'Desplegable') {
      return saveDesplegable(result);
    }
    if (type === 'Imagen') {
      return saveImagen(result);
    }
    if (type === 'Cita') {
      return saveCita(result);
    }
    if (type === 'Destacado') {
      return saveDestacado(result);
    }
    return saveDefaultContent();
  }
  
  const ContentTypeModalForm = ({
    content,
    type,
    saveContentType,
    editContentType
  }) => {
    let result = {};
    const setResult = (newResult) => {
      result = newResult;
    }
    const optionalContent = content || {};
    return (
    <>
      {getContentType(type, optionalContent, setResult)}
      {type &&
      <Grid container spacing={2}>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          type="submit"
          fullWidth
          style={{marginTop: '40px'}}
          onClick={() => content ? editContentType(checkContentAndSave(type, result)) : saveContentType(checkContentAndSave(type, result))}
        >
          Guardar
        </Button>
      </Grid>
    }
  </>
  );
}
  
  export default ContentTypeModalForm;