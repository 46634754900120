import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    padding: 30px 120px;
    font-size: 1.15rem;
    font-weight: 500;
    min-height: 100px;
    display: flex;
    background: #fcdac8;
    align-items: center;
    justify-content: center;
    &:after {
      position: absolute;
      top: 20px;
      left: 20px;
      content: '';
      width: 100px;
      height: 100px;
      border-radius: 50px;
      background-color: white;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100px;
    }
`;


