import {
  Typography,
  AppBar,
  Box,
  Button,
  Toolbar,
} from '@material-ui/core';
import {
  Save,
  CloudDownload,
  Delete,
  CloudUpload,
  ExitToApp,
  LocalLibrary
} from '@material-ui/icons';

import {
  RoundedDiv,
  LogoTitle
} from './AppHeader.styles'

const AppHeader = ({
  appBarStyle,
  titleStyle,
  saveData,
  downloadJson,
  uploadJson,
  openDeleteModal,
  logOut,
}) => (
  <AppBar position="static" className={appBarStyle}>
    <Toolbar>
      <RoundedDiv>
        <LocalLibrary fontSize="large" />
      </RoundedDiv>
      <Box m={1} />
      <LogoTitle
        className={titleStyle}
        variant="h4"
        component="h1"
      >
        ScormLab
      </LogoTitle>
      <Button
        variant="contained"
        size="small"
        onClick={saveData}
        startIcon={<Save />}
      >
        Guardar
      </Button>
      <Box m={2} />
      <Button
        variant="contained"
        size="small"
        startIcon={<CloudDownload />}
        onClick={downloadJson}
      >
        Descargar Fichero
      </Button>
      <Box m={2} />
      <Button
        variant="contained"
        size="small"
        startIcon={<CloudUpload />}
        onClick={uploadJson}
      >
        Subir Fichero
      </Button>
      <Box m={2} />
      <Button
        variant="contained"
        size="small"
        color="secondary"
        startIcon={<Delete />}
        onClick={openDeleteModal}
      >
        Borrar
      </Button>
    </Toolbar>
  </AppBar>
)

export default AppHeader;  