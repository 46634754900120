import React, { useState } from 'react';
import {
  Paper,
  Modal,
  Select,
  FormControl,
  InputLabel ,
  MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ContentTypeModalForm from './ContentTypeModalForm';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const FieldModal = ({
  modalClass,
  isModalOpen,
  closeModal,
  modalStyle,
  contentTypes,
  saveContentType,
}) => {
  const [selectedType, setSelectedType] = useState('');

  const handleChange = (type) => {
    setSelectedType(type)
  };

  const classes = useStyles();
  return (
    <Modal
      id='fieldModal'
      open={isModalOpen}
      onClose={closeModal}
      scroll='paper'
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper style={modalStyle} className={modalClass}>
        <FormControl className={classes.formControl}>
          <InputLabel id="selectModalFieldLabel">Tipo de contenido</InputLabel>
          <Select
            labelId="selectModalFieldLabel"
            id="selectModalField"
            onChange={(event) => handleChange(event.target.value)}
            value={selectedType}
            >
            {contentTypes.map(({id, type}) => (
              <MenuItem
                key={id}
                value={type}
              >
                {type}
              </MenuItem>
            ))}
          </Select>
          <ContentTypeModalForm
            type={selectedType}
            saveContentType={saveContentType}
          />
        </FormControl>
      </Paper>
    </Modal>
  );
}

export default FieldModal;