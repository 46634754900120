import {
  Paper,
  Modal,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';

import ContentTypeModalForm from './ContentTypeModalForm';

const EditModal = ({
  modalClass,
  isModalOpen,
  closeModal,
  modalStyle,
  content,
  saveContentType,
  editContentType
}) => {
  return (
    <Modal
      id='fieldModal'
      open={isModalOpen}
      onClose={closeModal}
      scroll='paper'
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper style={modalStyle} className={modalClass}>
          <InputLabel id="selectModalFieldLabel">Tipo de contenido</InputLabel>
          <Select
            labelId="selectModalFieldLabel"
            id="selectModalField"
            disabled
            fullWidth
            value={content.type}
            >
              <MenuItem
                value={content.type}
              >
              {content.type}
              </MenuItem>
            Editar
          </Select>
          <ContentTypeModalForm
            content={content}
            type={content.type}
            saveContentType={saveContentType}
            editContentType={editContentType}
          /> 
      </Paper>
    </Modal>
  );
}

export default EditModal;