import {
    TextField,
    Grid
  } from '@material-ui/core';

const PortadaForm = ({
  title,
  unit,
  image,
  icon
}) => {
  return (
    <Grid container spacing={2} id="portadaForm">
      <Grid item xs={12}>
        <TextField
          id='title'
          type="text"
          name="title"
          defaultValue={title || ''}
          helperText="Título Curso"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='unit'
          type="text"
          name="unit"
          defaultValue={unit || ''}
          helperText="Título Unidad"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='image'
          type="text"
          name="image"
          defaultValue={image || ''}
          helperText="Nombre imagen"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='icon'
          type="text"
          name="icon"
          defaultValue={icon || ''}
          helperText="Nombre icono"
          fullWidth
        />
      </Grid>
    </Grid>
  )
}

export default PortadaForm;
