
import React, { useState, useEffect } from 'react';
import {
  TextField,
  Box,
  Grid,
  Checkbox,
} from '@material-ui/core';

const Image = ({
  currentImageName,
  type,
  currentWidth,
  pendingImage,
  setResult
}) => {
const [havePendingImage, setPendingImage] = useState(pendingImage || false);
const [imageName, setImageName] = useState(currentImageName || '');
const [width, setWidth] = useState(currentWidth || 50);

const handleWidth = (value) => setWidth(value);
const handleImageName = (value) => setImageName(value);
const toogleHaveImage = () => setPendingImage(!havePendingImage);

useEffect(() => {
  setResult({
    type: 'Imagen',
    imageName,
    havePendingImage, 
    width: parseInt(width, 10),
  });
}, [havePendingImage, imageName, setResult, width]);
return (
  <Box>
    <form style={{width: '100%', marginTop: '10px', display: 'flex'}} noValidate autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="imageName"
            label="Nombre"
            name='imageName'
            onChange={(event) => handleImageName(event.target.value)}
            defaultValue={imageName || ''}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id={`width-${type}`}
            defaultValue={width}
            type="number"
            onChange={(event) => handleWidth(event.target.value)}
            helperText="Ancho"
            name='width'
          />
        </Grid>
        <Grid item xs={8}>
          <Checkbox checked={havePendingImage} onChange={toogleHaveImage}/> Pendiente de buscar
        </Grid>
      </Grid>
    </form>
  </Box>
  )
}

export default Image;