import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Checkbox,
} from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';

const Cita = ({
  setResult,
  text,
  currentWidth,
  currentImageName,
  animation
}) => {
  const [width, setWidth] = useState(currentWidth || 100);
  const [haveAnimation, setHaveAnimation] = useState(animation || false);
  const [imageName, setImageName] = useState(currentImageName || '');
  const [content, setContent] = useState(text || '');
  const handleEditorChange = (content) => {
    setContent(content);
  }
  const toogleAnimation = () => {
    const current = !haveAnimation;
    setHaveAnimation(current);
  }
  const handleWidth = (value) => setWidth(value);
  const handleImageName = (value) => setImageName(value);
  useEffect(() => {
    setResult({
      type: 'Cita',
      imageName,
      text: content,
      haveAnimation, 
      width: parseInt(width, 10),
    });
  }, [text, haveAnimation, width, content, setResult, imageName]);
  return (
    <Box m={2}>
      <TextField
        type="number"
        defaultValue={width}
        name="width"
        onChange={(event) => handleWidth(event.target.value)}
        helperText="Ancho"
      />
      <Checkbox checked={haveAnimation} onChange={toogleAnimation}/> Animación
      <TextField
        fullWidth
        id="imageName"
        label="Nombre de la imagen (si la tiene)"
        name='imageName'
        onChange={(event) => handleImageName(event.target.value)}
        defaultValue={imageName || ''}
        />
      <Box m={2} />
      <Editor
        apiKey="wpgdkh9gdhce4boqq7eqgahj601n8ca77pcfdxcx6vddgvc3"
        initialValue={content || ""}
        init={{
          height: 300,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            `undo redo | formatselect | bold italic backcolor |
            alignleft aligncenter alignright alignjustify |
            bullist numlist outdent indent | removeformat | help`
        }}
        onEditorChange={handleEditorChange}
      />
    </Box>
  )
}

  export default Cita
