import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import { StyledTab, StyledTabs } from './Tabs.styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function SimpleTabs({ items }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
        <StyledTabs value={value} onChange={handleChange} aria-label="simple tabs example">
          {items.map(({
            id,
            title
          }) => (
            <StyledTab
              key={id}
              label={title}
              {...a11yProps(0)}
            />
          ))}
        </StyledTabs>
      {items.map(({
        id,
        text,
        image,
        haveImage,
        havePendingImage
      }) => (
        <TabPanel
          key={id}
          value={value}
          index={id - 1}
        >
          <div dangerouslySetInnerHTML={{__html: text }} />
          {haveImage && (
            <p style={{ backgroundColor: `${havePendingImage ? '#cc513d' : '#108037f7'}` }}>Aqui va la imagen: {image}</p>
          )}
        </TabPanel>
      ))}
    </div>
  );
}