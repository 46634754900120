import styled from 'styled-components';

export const Square = styled.div`
    background: ${props => `${props.havePendingImage ? '#cc513d' : '#108037f7'}`};
    position: relative;
    height: 100px;
    width: ${props => `${props.width}%`};
`;
export const OverlayText = styled.div`
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;



