import {
    Square,
    OverlayText
  } from './Image.styles';
    
    const Image = ({ imageName, havePendingImage }) => (
      <div>
        <Square
          havePendingImage={havePendingImage}
        >
          <OverlayText>Esto es un cuadro demo que contiene la imagen: {imageName}</OverlayText>
        </Square>
      </div>
    )
    
    export default Image;