import Carousel from 'react-material-ui-carousel'
import { Paper } from '@material-ui/core'

const SimpleCarousel = ({ items }) => (
  <Carousel>
    {items.map(({
      id,
      text,
      title,
      image,
    }) => (
      <Paper key={id} style={{ backgroundColor: '#f58551', minHeight: '400px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <h1 style={{ color: 'white' }}>Título: {title}</h1>
        <h2 style={{ color: 'white' }}>Imagen: {image}</h2>
        <p style={{ color: 'white' }}>Descripción: 
          <div style={{ color: 'white' }} dangerouslySetInnerHTML={{ __html: text }} />
        </p>
      </Paper>
    ))}
  </Carousel>
)
    
export default SimpleCarousel;