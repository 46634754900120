import styled from 'styled-components';

export const ListContainer = styled.ul`
    padding-left: 40px;
    list-style: none;
    text-align: left;
    margin-bottom: 1.5rem;
`;
export const SubListContainer = styled.ul`
   padding-left: 30px;
   list-style: none;
`;
export const SubSubListContainer = styled.ul`
   padding-left: 20px;
   list-style: none;
`;
export const ListItem = styled.li`
    position: relative;
    margin: 10px 0;
    &:after {
      position: absolute;
      top: 6px;
      left: -30px;
      content: '';
      width: 12px;
      height: 12px;
      -webkit-border-radius: 12px;
      border-radius: 12px;
      background-color: #f58551;
    }
`;

export const ListSubItem = styled.li`
    position: relative;
    margin: 10px 0;
    &:after {
      background-color: #f58551;
      border-radius: 0;
      border-radius: 12px;
      content: '';
      height: 2px;
      left: -30px;
      position: absolute;
      top: 11px;
      width: 10px;
    }
`;
export const ListSubSubItem = styled.li`
    position: relative;
    margin: 10px 0;
    &:after {
      background-color: transparent;
      border-radius: 4px;
      border: 1px solid #f58551;
      content: '';
      height: 8px;
      left: -30px;
      position: absolute;
      top: 8px;
      width: 8px;
    }
`;


