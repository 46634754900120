import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const RoundedDiv = styled.div`
  background-color: white;
  color: #3f51b5;
  border-radius: 50%;
  width: 45px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoTitle = styled(Typography)`
  font-family: 'Kiwi Maru', serif;
`;
