import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

import Editor from './containers/Editor';
//import Login from './containers/Login';


function App() {
  const [userLogged, setUserLogged] = useState(null);
  /* useEffect(() => {
    const fetchUserStatus = async () => {
      try {
        const isUserLogged = true;
        setUserLogged(isUserLogged);
      } catch (error) {
        setUserLogged(false);
        console.log('User logg off')
      }
    }
    fetchUserStatus();
  }, []); */
  return (
    <Router>
      <Switch>
        <Route path="/">
          <Editor />
        </Route>
        <Route path="/editor">
          <Editor />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
