    
import styles from './styles.module.css';

const Exercice = ({ text, entregable, evaluable }) => (
  <div className={styles.htmlTextContainer}>
    <div>
      <p>Información del ejercicio, esto no va a generarse</p>
      <p><b>Entregable:</b> {`${entregable ? 'Sí' : 'No'}`}</p>
      <p><b>Evaluable:</b> {`${evaluable ? 'Sí' : 'No'}`}</p>
    </div>
    <div dangerouslySetInnerHTML={{__html: text}} />
  </div>
)
    
export default Exercice;