import React, { useState, useEffect } from 'react';
import {
  Box,
  Checkbox,
  TextField,
} from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';

const Exercice = ({
  setResult,
  text,
  currentWidth,
  currentEvaluable,
  currentEntregable
}) => {
  const [width, setWidth] = useState(currentWidth || 100);
  const [content, setContent] = useState(text || '');
  const [evaluable, setEvaluable] = useState(currentEvaluable || false);
  const [entregable, setEntregable] = useState(currentEntregable || false);
  const handleEditorChange = (content) => {
    setContent(content);
  }
  const toogleEvaluable = () => {
    setEvaluable(!evaluable);
  }
  const toogleEntregable = () => {
    setEntregable(!entregable);
  }
  const handleWidth = (value) => setWidth(value);
  useEffect(() => {
    setResult({
      type: 'Ejercicio',
      text: content,
      entregable,
      evaluable,
      width: parseInt(width, 10),
    });
  }, [text, width, content, setResult, entregable, evaluable]);
  return (
    <Box m={2}>
      <TextField
        type="number"
        defaultValue={width}
        name="width"
        onChange={(event) => handleWidth(event.target.value)}
        helperText="Ancho"
      />
      <Checkbox checked={entregable} onChange={toogleEntregable}/> Entregable
      <Checkbox checked={evaluable} onChange={toogleEvaluable}/> Evaluable
      <Box m={2} />
      <Editor
        apiKey="wpgdkh9gdhce4boqq7eqgahj601n8ca77pcfdxcx6vddgvc3"
        initialValue={content || ""}
        init={{
          height: 300,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            `undo redo | formatselect | link | bold italic backcolor |
            alignleft aligncenter alignright alignjustify |
            bullist numlist outdent indent | removeformat | help`
        }}
        onEditorChange={handleEditorChange}
      />
    </Box>
  )
}

  export default Exercice
