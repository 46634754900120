export async function signIn({
  username,
  password
}) {
    if(username == "hola@armandosalvador.com" && password == "Hola123!") {
      return await true;
    } 
    return await false;
}

export async function confirmPassword({
  cognitoUser,
  password
}) {
    return await true; //Auth.completeNewPassword(cognitoUser, password);
}

export async function signOut() {
    return await true; //Auth.signOut();
}

export async function forgotPassword(username) {
  return false;
}

export async function forgotPasswordSubmit({
  username,
  code,
  password
  }) {
  return await false;
}

