import {
    ListContainer,
    SubListContainer,
    SubSubListContainer,
    ListItem,
    ListSubItem,
    ListSubSubItem
  } from './List.styles';
    
const List = ({ items }) => (
  <ListContainer>
    {items.map(item => (
      <ListItem>{item.text}
        {item.children.length && (
          <SubListContainer>
            {item.children.map(children => (
              <ListSubItem>{children.text}
                {children.children.length && (
                  <SubSubListContainer>
                    {children.children.map(subChildren => (
                      <ListSubSubItem>{subChildren.text}</ListSubSubItem>
                    ))}
                  </SubSubListContainer>
                )}
              </ListSubItem>
            ))}
          </SubListContainer>
        )}
      </ListItem>
    ))}
  </ListContainer>
)
    
    export default List;