import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function SimpleAccordion({
  items
}) {
  return (
    <div>
      {items.map(({
        id,
        text,
        title,
        haveImage,
        havePendingImage,
        image
      }) => (
      <Accordion key={id}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${id}a-content`}
          id={`panel${id}a-header`}
        >
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: text }} />
          {haveImage && (
            <p style={{ backgroundColor: `${havePendingImage ? '#cc513d' : '#108037f7'}` }}>Aqui va la imagen: {image}</p>
          )}
        </AccordionDetails>
      </Accordion>
      ))}
    </div>
  );
}
