import styled from 'styled-components';

export const Title = styled.h1`
  padding: 22px 22px 20px;
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
  background: #f58551;
`;
export const Label = styled.span`
  color: #414864;
`;

